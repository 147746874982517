import { Redirect, RouteComponentProps } from '@reach/router'
import { Auth } from 'aws-amplify'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { Machine } from 'xstate'
import { interpret } from 'xstate/lib/interpreter'
import { getToken, login, verifyOtp } from '../../api/auth-api'
import { authWithSocialButtons, authWithUserPassForm } from '../../config'
import { colors } from '../../utils/colors'
import { generateGenericFavicon } from '../../utils/generateGenericFaviconForHelmet'
import SocialLogin from './SocialLogin'
import config from '../../config'
const { Card, Heading, TextInputField, Button, Text } = require('evergreen-ui')

interface IProps extends RouteComponentProps {}

interface ILoginStateSchema {
  states: {
    idle: {}
    validatingCredentials: {}
    otp: {}
    validatingOtp: {}
    final: {}
  }
}

type LoginEvent =
  | { type: 'LOGIN' }
  | { type: 'SUCCESS' }
  | { type: 'FAIL' }
  | { type: 'OTP_FLOW' }
  | { type: 'VERIFY' }
  | { type: 'GO_BACK' }

const loginMachine = Machine<{}, ILoginStateSchema, LoginEvent>({
  id: 'login',
  initial: 'idle',
  states: {
    idle: { on: { LOGIN: { target: 'validatingCredentials' } } },
    validatingCredentials: {
      on: {
        SUCCESS: 'final',
        FAIL: 'idle',
        OTP_FLOW: 'otp',
      },
    },
    otp: {
      on: {
        VERIFY: {
          target: 'validatingOtp',
        },
        GO_BACK: {
          target: 'idle',
        },
      },
    },
    validatingOtp: {
      on: {
        SUCCESS: 'final',
        FAIL: 'otp',
      },
    },
    final: {},
  },
})

interface IState {
  username: string
  password: string
  otp: string
  currentMachineState: any
  error: string
  tokenForOtpVerification: string
  name: string
  resetPassword: boolean
  activate2Fa: boolean
}

const initialState = {
  username: '',
  password: '',
  tokenForOtpVerification: '',
  error: '',
  otp: '',
  name: '',
  resetPassword:false,
  activate2Fa:false,
}

class LoginPage extends React.Component<IProps, IState> {
  public state = {
    currentMachineState: loginMachine.initialState,
    ...initialState,
  }

  private service = interpret(loginMachine).onTransition(currentMachineState =>
    this.setState({
      currentMachineState,
    }),
  )

  public componentDidMount() {
    this.service.start()
    if (getToken()) {
      this.service.send('SUCCESS')
    }
  }

  public componentWillUnmount() {
    this.service.stop()
  }

  public render() {
    const { currentMachineState } = this.state

    if (currentMachineState.matches('final')) {
      return <Redirect to={'/'} noThrow={true} />
    }

    if (
      currentMachineState.matches('idle') ||
      currentMachineState.matches('validatingCredentials')
    ) {
      return this.renderIdleState()
    }

    if (currentMachineState.matches('otp') || currentMachineState.matches('validatingOtp')) {
      return this.renderValidationState()
    }

    return (
      <pre>
        <h1>unknown state</h1>
        <code>{JSON.stringify(this.state, null, 2)}</code>
      </pre>
    )
  }

  private renderValidationState = () => {
    const { currentMachineState, error } = this.state
    return (
      <Container>
        <main>
          <Card background={'white'} padding={24}>
            <section className="validation-topbar">
              <Button iconBefore={'arrow-left'} appearance="minimal" onClick={this.goBackToLogin} />
              <Text className="validation-displayname">{this.state.name}</Text>
            </section>
            <Header>
              <Heading size={600}>Validation</Heading>
            </Header>
            <form onSubmit={this.verifyOtp}>
              <TextInputField
                name={'otp'}
                className={'validation-otp-text'}
                label={'Enter the 6-digit 2FA code'}
                placehodlder={'Enter code'}
                value={this.state.otp}
                onChange={this.changeOtp}
              />
              <br />
              <Button
                appearance="primary"
                type={'submit'}
                width={'100%'}
                style={{ justifyContent: 'center' }}
                isLoading={currentMachineState.matches('validatingOtp')}
              >
                Validate
              </Button>
              <Text className="error-message">{error}</Text>
              {this.ResetLink()}
            </form>
          </Card>
        </main>
      </Container>
    )
  }

  private federatedLogin = async (provider: any) => {
    //this.isLoading = true;
    await Auth.federatedSignIn({ provider: provider as any })
  }
  
  private renderIdleState = () => {
    const { currentMachineState, error } = this.state
    return (
      <Container>
        <Helmet link={generateGenericFavicon()}>
          <title>Login | Canopy Parser</title>
        </Helmet>
        <main>
          <Card background={colors.white} padding={24}>
            <Header>
              <Heading size={600}>Login</Heading>
            </Header>
            {authWithSocialButtons && <SocialLogin service={this.service}/>}
            {authWithUserPassForm && (
              <form onSubmit={this.login}>
                <TextInputField
                  name={'username'}
                  label={'Username'}
                  type={'username'}
                  placehodlder={'username'}
                  spellcheck={false}
                  value={this.state.username}
                  onChange={this.changeUsername}
                />
                <TextInputField
                  name={'password'}
                  label={'Password'}
                  type={'password'}
                  placehodlder={'password'}
                  value={this.state.password}
                  onChange={this.changePassword}
                />
                <br />
                <Button
                  appearance="primary"
                  type={'submit'}
                  width={'100%'}
                  style={{ justifyContent: 'center' }}
                  isLoading={currentMachineState.matches('validatingCredentials')}
                >
                  Login
                </Button>
                <Text className="error-message">{error}</Text>
                {this.ResetLink()}
              </form>
            )}
          </Card>
        </main>
      </Container>
    )
  }

  private goBackToLogin = () => {
    this.service.send('GO_BACK')
    this.setState({ ...initialState })
  }

  private verifyOtp = async (e: any) => {
    e.preventDefault()
    this.service.send('VERIFY')
    const { otp } = this.state
    const response: any = await verifyOtp({ otp })
    if (response.error) {
      this.service.send('FAIL')
      this.setState({ error: response.error, resetPassword:response.resetPassword })
    } else {
      this.service.send('SUCCESS')
    }
  }

  private login = async (e: any) => {
    e.preventDefault()
    const { username, password } = this.state
    this.setState({ error: '' })
    if (isEmpty(username)) {
      return
    }
    if (isEmpty(password)) {
      return
    }
    this.service.send('LOGIN')
    login({ username, password })
      .then((response: any) => {
        const { login_flow } = response
        if (login_flow === 'logged_in') {
          this.service.send('SUCCESS')
        } else if (login_flow === 'reset_password') {
          this.service.send('FAIL')
          this.setState({ error: 'Your password has expired.', resetPassword:true })
        } else {
          this.service.send('FAIL')
          this.setState({ error: 'Something went wrong' })
        }
      })
      .catch((e: any) => {
        if (e.response && e.response.body) {
          const error =
            e.response && e.response.body && e.response.body.error
              ? e.response.body.error
              : 'Something went wrong'
          this.setState({ error })
          this.service.send('FAIL')
        } else if (e.response && e.response.data) {
          const { data } = e.response
          if (data.login_flow === '2fa_verification') {
            this.service.send('OTP_FLOW')
          } else if (data.login_flow === '2fa_activation') {
            this.service.send('FAIL')
            this.setState({ error: '2fa needs to be activated.', activate2Fa:true })
          } else if (data.login_flow === 'reset_password') {
            this.service.send('FAIL')
            this.setState({ error: 'Your password has expired.' , resetPassword:true })
          } 
          else {
            this.setState({ error: (data.error||'Something went wrong') })
            this.service.send('FAIL')
          }
        } else {
          this.setState({ error: 'Something went wrong' })
          this.service.send('FAIL')
        }
      })
  }

  private ResetLink = () => {
    let content:any = ''
    if(config.api.viz_url){
      if(this.state.resetPassword){
        const targetUrl = config.api.viz_url + '/forgot-password'
        content = <a href={targetUrl} target="_blank" className="reset-link" title='Will open different app where password can be reset'>Reset Password</a>
      }else if (this.state.activate2Fa){
        const targetUrl = config.api.viz_url 
        content = <a href={targetUrl} target="_blank" className="reset-link" title='Will open different app where 2Fa can be activated'>Activate 2FA</a>
      }
    }
    return content
  }

  private changeUsername = (e: any) => this.setState({ username: e.target.value })
  private changePassword = (e: any) => this.setState({ password: e.target.value })
  private changeOtp = (e: any) => {
    const otp = e.target.value
    if ((otp === '' || otp.length <= 6) && /^\d*$/.test(otp)) {
      this.setState({ otp: e.target.value })
    }
  }
}

const isEmpty = (value: string) => {
  return !value
}

const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`

// noinspection CssUnknownTarget
const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #0e1e25;
  background-image: url('/assets/bg_${Math.floor((Math.random() * 2) + 1)}.jpg');
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  > main {
    margin: 12px;
    width: 20rem;
    @media (max-width: 500px) {
      margin: auto;
      width: 96%;
    }
    .error-message {
      display: block;
      line-height: 1rem;
      margin-top: 12px;
      font-size: 0.8rem;
      color: red;
    }
    .validation-topbar {
      display: flex;
      justify-content: space-between;
      margin: -24px 0 24px -24px;
      align-items: center;
    }
    .validation-otp-text {
      font-family: monospace, 'Dank Mono';
      letter-spacing: 6px;
      font-weight: 600;
    }
    .validation-displayname {
      font-style: italic;
    }
    .reset-link{
      font-size: 0.8rem;
      text-decoration: 'none';
    }
  }
`

export default LoginPage
